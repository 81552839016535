import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import type { ConfirmedTransportOrder, PutShipment, TransportOrder, TransportOrderList } from './orderExchange.types';
import {
    confirmedTransportOrderMapper,
    shipmentMapper,
    transportOrderListMapper,
    transportOrderMapper,
} from './orderExchange.mapper';

const baseUrl = `${config.backend.ORDER_EXCHANGE_SERVICE}`;

export const transportOrdersApi = createApi({
    reducerPath: 'transportOrders',
    tagTypes: ['TransportOrder'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        getOrders: builder.query<TransportOrderList, void>({
            query: () => ({
                url: '/outbound/transport-orders?embed=(shipments)',
            }),
            providesTags: result => [
                ...(result?.items ?? []).map(({ id }) => ({ type: 'TransportOrder' as const, id })),
                { type: 'TransportOrder', id: 'LIST' },
            ],
            transformResponse: transportOrderListMapper.toDomain,
        }),
        putTransportOrder: builder.mutation<void, TransportOrder>({
            query: transportOrder => ({
                url: `/outbound/transport-orders/${transportOrder.id}`,
                method: 'PUT',
                headers: { 'If-None-Match': '*' },
                body: transportOrderMapper.fromDomain(transportOrder),
            }),
            invalidatesTags: [{ type: 'TransportOrder', id: 'LIST' }],
        }),
        putShipment: builder.mutation<void, PutShipment>({
            query: shipment => ({
                url: `/outbound/transport-orders/${shipment.transportOrderId}/shipments/${shipment.shipment.id}`,
                method: 'PUT',
                headers: { 'If-None-Match': '*' },
                body: shipmentMapper.fromDomain(shipment.shipment),
            }),
            invalidatesTags: [{ type: 'TransportOrder', id: 'LIST' }],
        }),
        putConfirmedTransportOrder: builder.mutation<
            ConfirmedTransportOrder,
            {
                confirmedTransportOrder: ConfirmedTransportOrder;
                etag?: string;
            }
        >({
            query: ({ confirmedTransportOrder, etag }) => ({
                url: `/outbound/confirmed-transport-orders/${confirmedTransportOrder.id}`,
                method: 'PUT',
                headers: { 'If-Match': etag },
                body: confirmedTransportOrderMapper.fromDomain(confirmedTransportOrder),
            }),
            invalidatesTags: result => [{ type: 'TransportOrder', id: result?.id }],
            transformResponse: confirmedTransportOrderMapper.toDomain,
        }),
    }),
});

export const {
    useGetOrdersQuery,
    usePutTransportOrderMutation,
    usePutShipmentMutation,
    usePutConfirmedTransportOrderMutation,
} = transportOrdersApi;
